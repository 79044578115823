/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";

// import { message } from "ant-design-vue";
import dataAPI from '@_/util/dataApi.js'

// 环境的切换
console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV == "production") {
 // axios.defaults.baseURL = "http://172.19.30.156:8090"; //在生产环境中需要根据实际服务器的端口号来定这个url
  axios.defaults.baseURL = location.origin; 
}

// 请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    return config;
  },
  error => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      if(response.data.toString().indexOf('[object Blob]')>-1){
        return Promise.resolve({res:response.data,headers:response.headers});        
      }else{

        const { code, data, msg } = response.data;
        if (code == 0) {
          return Promise.resolve(response.data);
        } else {
       
          if (code == 1) {
            console.log("请联系管理员");
          
          }else{
            console.log(msg);
            //用户不能使用子系统， 跳转套主页
           if(code==1010){
            window.location.href =  window.location.origin
           }
          }
        }
      }
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  error => {
    console.log(error);
    if (error.response.status) {
      switch (error.response.status) {
        case 500:
          console.log("请联系管理员");

          break;

        case 403:
          console.log("接口403");
          break;
        // 404请求不存在
        case 404:
          console.log("接口404");
          break;
        // 其他错误，直接抛出错误提示
        default:
          console.log("请联系管理员");

      }
      return Promise.reject(error.response);
    }
  }
);

/**
 *
 * @param {*} url
 * @param {*} params
 */
export function getToken() {
  return new Promise((resolve, reject) => {
    httpGet(dataAPI['get_auth_token']).then(res => {
      resolve(res.authenticity_token);
    });
  });
}
export function httpGetFile(url, params){    
  return new Promise((resolve, reject) =>{        
      axios.get(url, {     
          responseType: 'blob',
          params: params        
      })        
      .then(({res,headers})=> {            
              resolve({res,headers});        
      })        
      .catch(err => {            
          reject(err)        
      })    
  });
}

export function getTokenWithoutCookies() {
  return new Promise((resolve, reject) => {
    axios
      .get(dataAPI['get_auth_token'],{withCredentials:false} )
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function httpGet(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function httpGetOne(url, params) {
  let tempurl = url.replace(/<:id>/g, params.id);
  delete params.id;
  return new Promise((resolve, reject) => {
    axios
      .get(tempurl)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function httpPost(url, params) {
  return new Promise((resolve, reject) => {
    getToken().then(token => {
      params.authenticity_token = token;
      axios
        .post(url, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  });
}

export function httpPostLogin(url, params) {
  return new Promise((resolve, reject) => {
      axios
        .post(url, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
  });
}

/**
 * post方法，对应post 文件方法请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数] File
 */
export function httpPostFile(url, params) {
  return new Promise((resolve, reject) => {
   getToken().then(token => {
       params.authenticity_token = token;
       
      axios.defaults.headers.post["Content-Type"] =
        "multipart/form-data;charset=utf-8";
      axios
        .post(url, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
   // });
  });
})
}



/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function httpPut(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

/**
 * put方法，对应patch请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function httpPatch(url, params) {  
  if(url.match(/<:id>/g)){
    url = url.replace(/<:id>/g, params.id);
    delete params.id;
  }

  return new Promise((resolve, reject) => {
    getToken().then(token => {
      params.authenticity_token = token;
      axios
        .patch(url, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    });
  });
}

/**
 * delete方法，对应pdelete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function httpDelete(url, params) {
  let tempurl = url.replace(/<:id>/g, params.id);
  delete params.id;
  return new Promise((resolve, reject) => {
    getToken().then(token => {
      params.authenticity_token = token;
      axios
        .delete(tempurl, { data: params })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    });
  });
}

export const axios_ = axios;
